import axios from "axios";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";
import React, { useEffect, useState } from "react";
import { Link, useParams,useLocation  } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Events() {
    const { id } = useParams(); 
    const [current_page, setCurrent_page] = useState(1);  
    const [links, setLinks] = useState([]);  
    const [events, setEvents] = useState([]); 
    const [eventages,setEventAges]=useState([]);

    // States for filters
    const [selectedCountry, setSelectedCountry] = useState(""); 
    const [selectedMode, setSelectedMode] = useState(""); 
    const [countryList, setCountryList] = useState([]);
    const [minAge, setMinAge] = useState(""); 
    const [maxAge, setMaxAge] = useState(""); 
    const location = useLocation();


    useEffect(() => {
        setSelectedCountry('');
        setSelectedMode('');
        // setSelectedAgeGroup('');
        setMinAge();
        setMaxAge();
    }, [location]);

    // Fetch events
    const fetchData = async (page) => {
        try {
            const countryQuery = 
            selectedCountry && selectedCountry !== "248"
                ? `&country=${Array.isArray(selectedCountry) ? selectedCountry.join(",") : selectedCountry}`
                : "";

            const modeQuery = selectedMode && selectedMode !== "All" ? `&mode=${selectedMode}` : "";
            const minAgeQuery = minAge && minAge !== "All" ? `&min_age=${minAge}` : `&min_age=`;
            const maxAgeQuery = maxAge && maxAge !== "All" ? `&max_age=${maxAge}` : `&max_age=`;

            const response = await axios.get(
                `${Front_API_URL}/allEvents/${id}?page=${page || current_page}${countryQuery}${modeQuery}${minAgeQuery}${maxAgeQuery}`
            );
            
            console.log('response.data.data check');
            console.log(response.data);
            // console.log('response.data.data',response.data.data.name)
           
            setEvents(response.data.data); 
            setCurrent_page(response.data.current_page); 
            setLinks(response.data.links); 
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await axios.get(`${Front_API_URL}/countries`);
            let countries = response.data;

            const country248 = countries.find((country) => country.id === 248);
            const country249 = countries.find((country) => country.id === 249);

            countries = countries.filter((country) => country.id !== 248 && country.id !== 249);

            const orderedCountries = [country248, country249, ...countries];

            setCountryList(orderedCountries);
        } catch (error) {
            console.error("Error fetching countries", error);
        }
    };

    useEffect(() => {
        fetchCountries();

    }, []);  

    useEffect(() => {
        fetchData(current_page);
    }, [current_page, id, selectedCountry, selectedMode,minAge,maxAge]);

    const pagination = (url) => {
        if (url) {
            const page = new URL(url).searchParams.get('page');
            setCurrent_page(Number(page));
        }
    };



    return (
        <>
            {/* Page Title Area */}
            <div className="banner-area events-bg">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Our Competitions</h2>
                                <ul>
                                    <li>
                                        <Link to="/"> Home </Link>
                                        <span class="straight-line">|</span>
                                        <p className="active">Competitions</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Title Area */}

            {/* Filters Section */}
            <div className="container">
                <div className="row my-4">
                    <div className="col-md-3 main-filter-cls">
                        <div className="row filter-bx">
                        {events.length > 0 && <h3>Filter for {events[0].name}</h3>}
                            <div className="col-md-12 filter-bx-12">
                                <label>Select Country</label>
                                <select
                                    className="form-control"
                                    value={selectedCountry}
                                    onChange={(e) => setSelectedCountry(e.target.value)}
                                >
                                    <option value="Choose">Choose</option>
                                    {countryList.map((country, index) => (
                                        <option key={index} value={country.id}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-12 filter-bx-12">
                                <label>Select Mode</label>
                                <select
                                    className="form-control"
                                    value={selectedMode}
                                    onChange={(e) => setSelectedMode(e.target.value)}
                                >
                                    <option value="All">Choose</option>
                                    <option value="3">Online/Onsite</option>
                                    <option value="1">Online</option>
                                    <option value="2">Onsite</option>
                                </select>
                            </div>


                            <div className="col-md-6 filter-bx-12">
                                <label>Min Age</label>
                                <select
                                    className="form-control"
                                    value={minAge}
                                    onChange={(e) => setMinAge(e.target.value)}
                                >
                                    <option value="All">Choose</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>

                                </select>
                            </div>
                            <div className="col-md-6 filter-bx-12">
                                <label>Max Age</label>
                                <select
                                    className="form-control"
                                    value={maxAge}
                                    onChange={(e) => setMaxAge(e.target.value)}
                                >
                                    <option value="All">Choose</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <section className="events">
                            <div className="container">
                                {events.length === 0 ? (
                                    <div className="no-data-found text-center">
                                        <h3>No Data Found!</h3>
                                    </div>
                                ) : (
                                    <>
                                        <div className="row">
                                            {events.map((item, index) => (
                                                <div className="col-lg-12 col-md-12 p_0" key={index}>
                                                    <div className="single-events event-cls">
                                                        <div className="events-img">
                                                            <Link to={`/events-details/${item.c_id}`}>
                                                                <img src={`${Front_URL}/storage/images/${item.image}`} alt="competitions" />
                                                            </Link>
                                                        </div>
                                                        <div className="content">
                                                            <Link to={`/events-details/${item.c_id}`}>
                                                                <h2>{item.title}</h2>
                                                            </Link>
                                                            <div className="competition-details-r">
                                                                <h5 ><span class="w-48">Mode</span> <span class="w-4">:</span>  
                                                                <span class="w-48">{item.mode === 1 ? 'Online' : item.mode === 2 ? 'Onsite' : item.mode === 3 ? 'Online/Onsite' : ''} </span> </h5>
                                                                <h5><span class="w-48">Country</span> <span class="w-4">:</span>  <span class="w-48">{item.countryname}</span></h5>
                                                                <h5><span class="w-48">Age Group</span> <span class="w-4">:</span>  <span class="w-48">{item.age_group}</span></h5>
                                                                <h5><span class="w-48">Competition Name</span ><span class="w-4"> :</span>  <span class="w-48">{item.name}</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    
                                      <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <ul className="pagination">
                                                {/* Check if there is a previous page and show "<" */}
                                                {links[0]?.url && (
                                                    <li className="page-item">
                                                        <a
                                                            href="#"
                                                            className="page-link waves-effect"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                pagination(links[0].url);
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    behavior: 'smooth' // Smooth scrolling to the top
                                                                });
                                                            }}
                                                        >
                                                            {"<"}
                                                        </a>
                                                    </li>
                                                )}

                                                {/* Render page numbers */}
                                                {links && links.slice(1, -1).map((item, index) => (
                                                    <li key={index} className={`page-item ${item.active ? 'active' : ''}`}>
                                                        <a
                                                            href="#"
                                                            className="page-link waves-effect"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                pagination(item.url);
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </a>
                                                    </li>
                                                ))}

                                                {/* Check if there is a next page and show ">" */}
                                                {links[links.length - 1]?.url && (
                                                    <li className="page-item">
                                                        <a
                                                            href="#"
                                                            className="page-link waves-effect"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                pagination(links[links.length - 1].url);
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }}
                                                        >
                                                            {">"}
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                      </div>

                                    </>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}
